import React, { useState, useEffect } from "react"
import Layout, { MyLocationEnum } from "../components/Layout"
import SEO, { MetaOG } from "../components/Seo"
import { graphql } from "gatsby"
import { TransitionStatus } from "react-transition-group/Transition"
import MobileStoryRoom from "../components/StoryRooms/MobileStoryRoom"
import BannerImage from "../images/haustradstefnaSocial.png"
const LazyApp = React.lazy(() => import('../components/App/App'));

import {
  ChatEmbed,
  ChatSideBar,
  useChat,
  CHAT_NAME,
  SubroomNameEnum
} from '../components/Chat'
import ChatMobile from "../components/Chat/ChatMobile"
import styled from "styled-components"

interface StoryRoomProps {
  data: any,
  location: Location,
  transitionStatus: TransitionStatus,
}

export default ({ data, location, transitionStatus }: StoryRoomProps) => {

  const seo_og: MetaOG = {
    title: "Innovation og CrossIndustryService",
    description: "",
  }

  const [showMobile, setShowMobile] = useState(false)
  useEffect(() => {
    var resp = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    setShowMobile(resp)
  })

  const {
    chatNickname,
  } = useChat()


  const isSSR = typeof window === "undefined"

  if (!showMobile) {
    return (
      <Layout
        myLocation={MyLocationEnum.Innovation}
        location={location}
        transitionStatus={transitionStatus}
        showMessage={true}
      >
        {({ infoOpen, messageOpen, setMessageOpen }) => (
          <>
            <SEO
              title="Nærmynd"
              description=""
              og={seo_og}
            />

            <ChatSideBar onClose={() => setMessageOpen(false)} show={messageOpen}>
              {chatNickname.fetching === false && (
                <ChatEmbed
                  chatName={CHAT_NAME}
                  nickname={chatNickname.nickname}
                  subroom={SubroomNameEnum.Innovation}
                />
              )}
            </ChatSideBar>

            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <LazyApp
                  data={data}
                  transitionStatus={transitionStatus}
                  leftColor="#3AB166"
                  rightColor="#3AB166"
                  topColor="#50EA84"
                  floorColor="#2D8F50"
                />
              </React.Suspense>
            )}
          </>
        )}
      </Layout>
    )
  }
  else {
    return (
      <LayoutMobile
        myLocation={MyLocationEnum.Innovation}
        location={location}
        transitionStatus={transitionStatus}
      >
        <SEO
          title="Nærmynd"
          description="Ýmsar sögur úr bransanum"
          og={seo_og}
        />

        {chatNickname.fetching === false && (
          <ChatMobile>
            <ChatEmbed
              chatName={CHAT_NAME}
              nickname={chatNickname.nickname}
              subroom={SubroomNameEnum.Innovation}
            />
          </ChatMobile>
        )}

      </LayoutMobile>
    )
  }

}

const LayoutMobile = styled(Layout)`
  background: #5166E7;
`

export const query = graphql`
  query {
    ceiling: file(relativePath: { eq: "Loft-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    floor: file(relativePath: { eq: "squoosh_roomFloor.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    concreteWall: file(relativePath: { eq: "Spacetile.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allRoom(filter: {roomName: {eq: "innovationogcrossindustryservice"}}) {
      edges {
        node {
          thirdImageNode {
            publicURL
          }
        }
      }
    }
  } 
`